import React from 'react';
import './notfound.css';

function NotFound() {
	return (
		<div className="notfound-container">
			<h1>Page not found</h1>
		</div>
	);
}

export default NotFound;
