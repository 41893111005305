import React from 'react'
import "./sidebar.css";
import logo192 from '../../assets/logo192.png'
import {
  HomeOutlined,
  Timeline,
} from "@material-ui/icons";
import { NavLink } from "react-router-dom";

export default function Sidebar({ url }) {
  return (
    <div className="sidebar">
      <div className="sidebarWrapper">
        <div className="sidebarMenu">
          {/* <h3 className="sidebarTitle">Dashboard</h3> */}
          <ul className="sidebarList">
            {/*<NavLink to={`${url}`} className="link">
            <li className="sidebarListItem">
              <HomeOutlined className="sidebarIcon" />
              Home
            </li>
            </NavLink>*/}
            <NavLink to={`${url}/audit-log`} className="link">
            <li className="sidebarListItem">
              <Timeline className="sidebarIcon" />
              Audit Log
            </li>
            </NavLink>
          </ul>
        </div>
        <div className="logo-bottom">Powered by <img className="zImage" src={logo192} alt="Zbrains" /><span className="zText">Brains</span></div>
      </div>
    </div>
  );
}
