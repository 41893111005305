import React, { useState } from 'react'
import './table.css'
import { DataGrid } from '@material-ui/data-grid'


export default function Table({ columns, APIData, PageSize }) {
    const [sortModel, setSortModel] = useState([{ field: 'id', sort: 'asc' }]);
    return (
        <div className="table white-background">
            <DataGrid
                sortModel={sortModel}
                onSortModelChange={(model) => setSortModel(model)}
                rows={APIData}
                rowSize="20"
                disableSelectionOnClick
                columns={columns}
                pageSize={PageSize}
            />
        </div>
    )
}
