import React, { useEffect, useState } from 'react'
import "./userList.css";
import axios from 'axios'
import moment from 'moment'
import DateMoment from '@date-io/moment';
import { useAuthState } from '../../Context';
import {
  DateTimePicker,
  MuiPickersUtilsProvider
} from '@material-ui/pickers';
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from '@material-ui/core';
import Table from '../../components/table/Table';
import GridCellExpand from '../../components/expandcell/GridCellExpand';
import { now } from 'moment';

// this is being used by grid table to expand cell for message
function renderCellExpand(params) {
  return (
    <GridCellExpand
      value={params.value ? params.value.toString() : ''}
      width={params.colDef.width + 50}
    />
  );
}

export default function UserList() {
  const [APIData, setAPIDate] = useState([]);
  const [moduleList, setModuleList] = useState([]);
  let oneDayPrior = moment()
  oneDayPrior.add(-1, 'days')
  const [selectedFromDate, setFromDate] = useState(oneDayPrior.format())
  const [selectedToDate, setToDate] = useState(moment().format())
  const [moduleFilter, setModuleFilter] = useState("287")

  const handleModuleFilter = (event) => {
    setModuleFilter(event.target.value);
    console.log(moment().format())
  }

  const handleFromDateChange = (m) => {
    console.log(m.format());
    setFromDate(m.format())
  }

  const handleToDateChange = (m) => {
    console.log(m.format());
    setToDate(m.format())
  }

  const { user } = useAuthState();

  useEffect(() => {

    axios.get(`/api/modmaps?account_id=${user["account_id"]}`)
      .then((response) => {
        setModuleList(response.data)
      })

  }, [])
  // account_id=${user["account_id"]}&modmapid=${moduleFilter}`
  useEffect(() => {
    axios.get('/api/pull', {
      params: {
        account_id: user["account_id"],
        modmapid: moduleFilter,
        from_date: selectedFromDate,
        to_date: selectedToDate
      }
    })
      .then((response) => {
        setAPIDate(response.data)
        console.log(response.data)
      }).catch((error) => {
        console.log(error)
      })
  }, [moduleFilter])
  
  const columns = [
    { field: "id", headerName: "ID", width: 90 },
    {
      field: "message",
      headerName: "Message",
      width: 200,
      renderCell: renderCellExpand,
      sortable: false
    },
    {
      field: "src_record_id",
      headerName: "Source Record ID",
      width: 100,
      sortComparator: (v1, v2, param1, param2) =>
      param1.api.getCellValue(param1.id, 'src_record_id') -
      param2.api.getCellValue(param2.id, 'src_record_id')
    },
    {
      field: "module_mapping_id",
      headerName: "Module Mapping ID",
      width: 100
    },
    {
      field: "record_id",
      headerName: "DB Record ID",
      width: 160
    },
    {
      field: "created_time",
      headerName: "Created Date",
      type: 'dateTime',
      width: 200
    },
  ];

  return (
    <div className="audit-log">
      <h2 className="audit-log-header">Audit Log</h2>
      <div className="featured">
      <div className="featuredItem white-background">
        <MuiPickersUtilsProvider utils={DateMoment}>
          <DateTimePicker
            label="From Date"
            inputVariant="outlined"
            value={selectedFromDate}
            onChange={handleFromDateChange}
          />
          <DateTimePicker
            label="To Date"
            inputVariant="outlined"
            value={selectedToDate}
            onChange={handleToDateChange}
          />
        </MuiPickersUtilsProvider>
      </div>
      <div className="featuredItem white-background">
        <FormControl variant="outlined" className="filter-formControl">
          <InputLabel id="demo-simple-select-outlined-label">Module Filter</InputLabel>
          <Select
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            value={moduleFilter}
            onChange={handleModuleFilter}
            label="Module Filter"
          >            
            {moduleList.map((modMap) => (
              <MenuItem value={modMap.modMapID}>{modMap.sourceName}{" --> "}{modMap.destName}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      </div>
      <Table
        columns={columns}
        APIData={APIData}
        PageSize={20}
      />
    </div>
  );
}