import React from 'react'
import Chart from "../../components/chart/Chart";
import FeaturedInfo from "../../components/featuredInfo/FeaturedInfo";
import "./dashhome.css";
import { userData } from "../../dummyData";
import WidgetSm from "../../components/widgetSm/WidgetSm";
import WidgetLg from "../../components/widgetLg/WidgetLg";

export default function DashHome() {

    return (
      <div className="home">
        <FeaturedInfo />
        <Chart data={userData} title="User Analytics" grid dataKey="Active User"/>
        <div className="homeWidgets">
          <WidgetSm/>
          <WidgetLg/>
        </div>
      </div>
    );
}

/*
import React, { useEffect, useState } from 'react'
import queryString from "query-string";
import { useLocation, useHistory, Redirect } from 'react-router-dom';
import Chart from "../../components/chart/Chart";
import FeaturedInfo from "../../components/featuredInfo/FeaturedInfo";
import "./home.css";
import { userData } from "../../dummyData";
import WidgetSm from "../../components/widgetSm/WidgetSm";
import WidgetLg from "../../components/widgetLg/WidgetLg";
import Login from '../login/Login';

function CheckLogin() {
  const tokenString = sessionStorage.getItem('token');

  if (tokenString) {
    return true
  } else {
    return false
  }
}

// let loggedIn = false

export default function Home() {
  let history = useHistory();

  const [loggedIn, setLoggedIn] = useState(false);

  useEffect(() => {
    console.log("Heelo from Home", loggedIn)
    setLoggedIn(CheckLogin())
  }, [])

    console.log(loggedIn)

    if (!loggedIn) {
      return <Login />
    }

    return (
      <div className="home">
        <FeaturedInfo />
        <Chart data={userData} title="User Analytics" grid dataKey="Active User"/>
        <div className="homeWidgets">
          <WidgetSm/>
          <WidgetLg/>
        </div>
      </div>
    );
  }
*/