import React from 'react';

import { loginUserBegin, useAuthState, useAuthDispatch } from '../../Context';
import './login.css';
import logo192 from '../../assets/logo192.png'
import agilitySync from '../../assets/agility-sync.png'

function Login() {

  const dispatch = useAuthDispatch();
  const { loading, errorMessage } = useAuthState();

  const handleLogin = async () => {
    loginUserBegin(dispatch);
  };

  return (
    <div className="login-wrapper">
      <div className="login-card">
      <img className="agilityLogo" src={agilitySync} alt="Agility Sync" /> 
      {errorMessage ? <p className="error">{errorMessage}</p> : null}
        <button
          className="login-button"
          onClick={handleLogin}
          disabled={loading}
        >
          Login With Zoho
        </button>
        <p className="white-text bolder">Need an account? <a className="orange-link" href="mailto:support@zbrains.net?subject=AgilitySync Log Account Creation">Email Us</a>.</p>
        <p className="white-text zbrains">Powered by  <img className="zbrains-logo" src={logo192} alt="zbrains logo" /><span className="bolder bigger">Brains</span></p>
      </div>
    </div>
  );
}

export default Login;


/*
import React, { useState } from 'react';
// import PropTypes from 'prop-types'
import { Button } from '@material-ui/core'

import './login.css';

// async function loginUser() {
//     const authURI = process.env.REACT_APP_ZOHO_AUTH
//     console.log("Auth: ", authURI)
//     fetch(authURI, {
//       method: 'GET'
//     })
//       .then(data => data.json())
//       .catch(err => console.log(err))
// }

export default function Login() {

  // const handleSubmit = async e => {
  //   e.preventDefault();
  //   await loginUser();
  // }

  return(
    <div className="login-wrapper">
      <a href={process.env.REACT_APP_ZOHO_AUTH}><button className="login-button">
        Login With Zoho
      </button>
      </a>
    </div>
  )
}

// Login.propTypes = {
//     setToken: PropTypes.func.isRequired
// }
*/