import React from "react";
import "./dashboard.css";
import { useRouteMatch, Switch } from "react-router-dom";
import dashRoutes from "../../Config/dashRoutes";
import AppRoute from "../../components/AppRoute";
import Topbar from "../../components/topbar/Topbar"
import Sidebar from "../../components/sidebar/Sidebar"

function Dashboard() {

  let { path, url } = useRouteMatch();

  return (
    <div>
      <Topbar />
      <div className="container">
      <Sidebar url={url} />
        <Switch>
            {dashRoutes.map((route) => (
                <AppRoute
                key={route.path}
                path={`${path}${route.path}`}
                component={route.component}
                isPrivate={route.isPrivate}
                exact={route.isExact}
                />
            ))}
        </Switch>
      </div>
    </div>
  );
}

export default Dashboard;