import DashHome from "../Pages/dashhome/DashHome";
import UserList from "../Pages/userList/UserList";
// import SubPage2 from "../Pages/Dashboard/SubPage2";

const routes = [
  {
    path: "/",
    component: DashHome,
    isPrivate: true,
    isExact: true
  },
  {
    path: "/audit-log",
    component: UserList,
    isPrivate: true,
    isExact: false
  }
];

export default routes;