import { useHistory } from "react-router-dom";
import axios from 'axios'

const AUTH_URL = "https://accounts.zoho.com/oauth/v2/auth";
const ZOHO_CLIENT_ID = process.env.REACT_APP_ZOHO_CLIENT_ID
const ZOHO_REDIRECT_URI = process.env.REACT_APP_ZOHO_REDIRECT_URI

export async function loginUserBegin(dispatch) {
	
    dispatch({ type: 'REQUEST_LOGIN' });

	// await axios.get(AUTH_URL, {
	// 	params: {
	// 	  client_id: ZOHO_CLIENT_ID,
	// 	  response_type: "code",
	// 	  redirect_uri: "http://localhost:3000/callback",
	// 	  scope: "AaaServer.profile.READ",
	// 	  access_type: "offline"
	// 	}
	//   })

	const zoho_auth_url = new URL(AUTH_URL)
	zoho_auth_url.searchParams.append("client_id", ZOHO_CLIENT_ID)
	zoho_auth_url.searchParams.append("response_type", "code")
	zoho_auth_url.searchParams.append("redirect_uri", ZOHO_REDIRECT_URI)
	zoho_auth_url.searchParams.append("scope", "AaaServer.profile.READ")
	zoho_auth_url.searchParams.append("access_type", "offline")

	window.open(zoho_auth_url, '_self');

	return 
}

export async function loginCallback(dispatch, loginPayload) {

	const { code, location, server } = loginPayload

	console.log(code, location, server)

	// const idTokenSplit = id_token.split(".")

	// const emailStr = decodeURIComponent(escape(window.atob(idTokenSplit[1])))

	// const emailObj = JSON.parse(emailStr)

	const codeJSON = {
		"code": code
	}	

	const requestOptions = {
		method: 'post',
		url: '/api/login',
		headers: { 'Content-Type': 'application/json' },
		data: codeJSON,
	};

	try {
		let response = await axios(requestOptions);

		const data = {
			user: response.data
		}

		localStorage.setItem('currentUser', JSON.stringify(data))

		return data;
	} catch (error) {

		if (error.response) {
			return { error: error.response.data, status_code: error.response.status}
		} else if (error.request) {
			return { error: JSON.stringify(error.request), status_code: 700 }
		}

		const data = {
			error: JSON.stringify(error)
		}
		return data
	}
}

export async function loginUser(dispatch, error) {

	if (error) {
		dispatch({ type: 'LOGIN_ERROR', error })

		return { error }
	}

	const data = localStorage.getItem('currentUser')
	if (!data) {
		dispatch({ type: 'LOGIN_ERROR', error: 'Please login'})
		return { error: 'Please login' }
	}
	const dataObj =  JSON.parse(data)
	dispatch({ type: 'LOGIN_SUCCESS', payload: dataObj})

	return dataObj;
}

export async function loginError(dispatch, error) {
	dispatch({ type: 'LOGIN_ERROR', error })

	return { error }
}

export async function logout(dispatch) {
	dispatch({ type: 'LOGOUT' });
	localStorage.removeItem('currentUser');
	localStorage.removeItem('token');
}
