import React, { useEffect } from 'react'
import './callback.css'
import queryString from "query-string";
import { useLocation, useHistory } from 'react-router-dom';
import { LinearProgress } from '@material-ui/core';
import { loginCallback, useAuthDispatch } from '../../Context';

export default function Callback() {
    let history = useHistory();
    const { hash, search } = useLocation()
    const dispatch = useAuthDispatch();

    let urlObj;
    if (hash) {
        urlObj = queryString.parse(hash)
    } else if (search) {
        urlObj = queryString.parse(search)
    }

    const auth_error = search ? urlObj.error : null

    useEffect( () => {
        async function callAction() {
            if (!search && !hash) {
                history.push('/authcheck')
                return
            }
            if (auth_error) {
                history.push({
                    pathname: '/authcheck',
                    state: { auth_error }
                })

                return
            }
            try {
                let response = await loginCallback(dispatch, urlObj);
                if (response.error) {
                    history.push({
                        pathname: '/authcheck',
                        state: { auth_error: response.error }
                    })
                    return
                }
                history.push('/authcheck');
            } catch (error) {
                console.log(error);
            }
        }

        callAction();
    }, [])

    

    return (
        <div className="callback">
            <p className="callback-loading-text">Authenticating... </p>
            <LinearProgress color="secondary" />
        </div>
    )
}

/*
import React from 'react'
import queryString from "query-string";
import { useLocation, useHistory } from 'react-router-dom'

export default function Callback() {
    let history = useHistory();
    const { hash } = useLocation()

    const { access_token, id_token } = queryString.parse(hash)

    sessionStorage.setItem('token', JSON.stringify(access_token));
    sessionStorage.setItem('id_token', JSON.stringify(id_token));

    history.push("/")

    return (
        <div className="callback">
            Authenticating...
        </div>
    )
}
*/
