/*
import React, { useState } from 'react'
import './App.css'
import {Route, BrowserRouter, Switch, Redirect } from "react-router-dom";
import Login from "./Pages/login/Login";
import Home from "./Pages/home/Home";
import UserList from "./Pages/userList/UserList";
import Topbar from './components/topbar/Topbar';
import Sidebar from './components/sidebar/Sidebar';
import Callback from './Pages/callback/Callback';
import Logout from './Pages/logout/Logout';

function App() {

  return (
    <BrowserRouter>
      <Topbar />
      <div className="container">
        <Sidebar />
        <Switch>
          <Route exact path="/">
            <Home />
          </Route>
          <Route exact path="/audit-log">
            <UserList />
          </Route>
          <Route exact path="/callback">
            <Callback />
          </Route>
          <Route exact path="/logout">
            <Logout />
          </Route>

          {/* <Route exact path="/login">
            <Login />
          </Route> *//*}
        </Switch>
      </div>
    </BrowserRouter>
  );
}

export default App;

*/

import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import routes from "./Config/routes.js";
import { AuthProvider } from "./Context";
import AppRoute from "./components/AppRoute";

function App() {
  return (
    <AuthProvider>
      <Router>
        <Switch>
          {routes.map((route) => (
            <AppRoute
              key={route.path}
              path={route.path}
              component={route.component}
              isPrivate={route.isPrivate}
              exact={route.isExact}
            />
          ))}
        </Switch>
      </Router>
    </AuthProvider>
  );
}

export default App;
