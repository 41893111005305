import React, { useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom';
import { loginUser,  useAuthSatate, useAuthDispatch } from '../../Context';

export default function AuthCheck() {
    const dispatch = useAuthDispatch();
    const history = useHistory();
    const location = useLocation();

    useEffect(() => {
        async function callAction() {
            try {
                let response = await loginUser(dispatch, location.state ? location.state.auth_error : null);
                if (response.error) {
                    history.replace('/login')
                }
                history.push('/dashboard');
            } catch (error) {
                console.log(error);
            }
        }

        callAction();
    }, [])

    return (
        <div></div>
    )
}