import React from 'react'
import './topbar.css'
import agilitySyncLogo from '../../assets/agility-sync.png'
import { useHistory } from 'react-router-dom';
import { useAuthDispatch, logout, useAuthState } from "../../Context";
import { NotificationsNone, Language, ExitToApp, Person } from '@material-ui/icons';
import SettingsOutlined from '@material-ui/icons/SettingsOutlined';
import { Button,Menu,MenuItem } from '@material-ui/core';


export default function Topbar() {
  let history = useHistory();
  const dispatch = useAuthDispatch();
  const userDetails = useAuthState();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  }

  const handleLogout = () => {
    handleClose()
    logout(dispatch);
    history.push("/login");
  };

  return (
    <div className="topbar">
      <div className="topbarWrapper">
        <div className="topLeft">
                    <img className="logo" src={agilitySyncLogo} alt="Agility Sync" />
                </div>
        <div className="topRight">
        <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
  <SettingsOutlined style={{color:'white'}} />
</Button>
<Menu
  id="simple-menu"
  anchorEl={anchorEl}
  keepMounted
  open={Boolean(anchorEl)}
  onClose={handleClose}
>
  <MenuItem onClick={handleClose}>Profile</MenuItem>
  {/* <MenuItem onClick={handleClose}>My account</MenuItem> */}
  <MenuItem onClick={handleLogout}><ExitToApp/> Logout</MenuItem>
</Menu>
          {/* <div className="topbarIconContainer">
            <NotificationsNone />
            <span className="topIconBadge">2</span>
          </div>
          <div className="topbarIconContainer">
            <Language />
            <span className="topIconBadge">2</span>
          </div>
          <div className="topbarIconContainer">
            {userDetails.user ?
              <Button
                startIcon={<ExitToApp />}
                onClick={handleLogout}
              >
              </Button>
              :
              <Person />
            }
          </div> */}
        </div>
      </div>
    </div>
  )
}


/*
import React, { useState, useEffect} from 'react'
import './topbar.css'
import { useHistory, Redirect } from 'react-router-dom';
import { NotificationsNone, Language, Settings, ExitToApp, Person } from '@material-ui/icons';
import { Button } from '@material-ui/core';

function CheckLogin() {
    const tokenString = sessionStorage.getItem('token');

    if (tokenString) {
      return true
    } else {
      return false
    }
}

export default function Topbar() {
    let history = useHistory();

    const [loggedIn, setLoggedIn] = useState(false);

    useEffect(() => {
        console.log("Heelo from Topbar", loggedIn)
        setLoggedIn(CheckLogin())
      }, [])

      const handleLogout = () => {
        sessionStorage.removeItem('token');
        console.log("logout");
        setLoggedIn(false);
        history.push("/logout")
        // return <Redirect to="/audit-log" />
    }

    return (
        <div className="topbar">
            <div className="topbarWrapper">
                <div className="topLeft">
                    <span className="logo">Audit Log</span>
                </div>
                <div className="topRight">
                    <div className="topbarIconContainer">
                        <NotificationsNone />
                        <span className="topIconBadge">2</span>
                    </div>
                    <div className="topbarIconContainer">
                        <Language />
                        <span className="topIconBadge">2</span>
                    </div>
                    <div className="topbarIconContainer">
                        { loggedIn ?
                        <Button
                            startIcon={<ExitToApp />}
                            onClick={handleLogout}
                        >
                        </Button>
                        :
                        <Person />
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}
*/
